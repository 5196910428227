import { createBrowserRouter } from "react-router-dom";
import App from "./App";
import AboutPage from "./components/aboutus/AboutPage";
import ServicesPage from "./components/services/ServicesPage";
import PortfolioPage from "./components/portfolio/PortfolioPage";
import GamingPage from "./components/gaming/GamingPage";
import TechnologyPage from "./components/technology/TechnologyPage";
import ContactPage from "./components/contactus/ContactPage";
import HomePage from "./components/home/HomePage";
import Credits from "./components/caredits/Credits"
import TwinCarsPrivacy from "./components/privacypoilcies/TwinCarsPrivacy";

export const rootRouter = createBrowserRouter([
    {
        path: '/',
        element: <App />,
        children: [

            {
                path: '',
                element: <HomePage />
            },
            {
                path: 'aboutus',
                element: <AboutPage />
            },
            {
                path: 'services',
                element: <ServicesPage />
            },
            {
                path: 'portfolio',
                element: <PortfolioPage />
            },
            {
                path: 'gaming',
                element: <GamingPage />
            },
            // {
            //     path: 'technology',
            //     element: <TechnologyPage />
            // },
            {
                path: 'contactus',
                element: <ContactPage />
            },
            // {
            //     path: 'credits',
            //     element: <Credits />
            // },
            {
                path: 'twin-cars-privacy',
                element: <TwinCarsPrivacy />
            },

            {
                path: 'static/media/*.jpg',
                element: <h1>Not found</h1>
            },

        ]
    }
])