import React from 'react'
import bannerbg from '../../assets/images/banner.jpg'
import img from '../../assets/images/bgpic.jpg'
import googleplay from '../../assets/images/googleplay.png'
import barrier from '../../assets/images/twincars/barrier.png'
import cars from '../../assets/images/twincars/cars.png'
import dotSquareBlue from '../../assets/images/twincars/dot1.png'
import dotSquareRed from '../../assets/images/twincars/dot2.png'
import dotCircleRed from '../../assets/images/twincars/dot3.png'
import dotCircleBlue from '../../assets/images/twincars/dot4.png'
import appStoreIcon from '../../assets/images/appstore.png'
import PageHeader from '../common/PageHeader'
import TechForGaming from '../technology/TechForGaming'

export default function GamingPage() {
  return (
    <div>
      <PageHeader img={img}>
        Bringing Fun to Life<br />
        We are dedicated to creating the best gaming experiences
      </PageHeader>
      <div className='flex justify-center md:justify-end mx-24 mb-32 md:mb-24'>
        <h2 className='text-white text-5xl md:text-6xl text-center md:text-right pt-14 font-extrabold pb-4 w-max border-white'><span className='text-transparent bg-clip-text bg-gradient-to-r from-blue-500 via-purple-600 to-pink-400'>Gaming</span> &&nbsp;Fun </h2>
      </div>
      <div className='flex flex-col px-10 md:p-24'>
        <div className='flex w-3/4 mb-8 self-center justify-between'>
          <img className='w-4 h-4 md:w-8 md:h-8 lg:w-12 lg:h-12 -translate-y-3' src={dotCircleBlue} alt='dot' />
          <img className='w-4 h-4 md:w-8 md:h-8 lg:w-12 lg:h-12 -translate-y-5' src={dotSquareBlue} alt='dot' />
          <img className='w-4 h-4 md:w-8 md:h-8 lg:w-12 lg:h-12 -translate-y-7' src={dotCircleRed} alt='dot' />
          <img className='w-4 h-4 md:w-8 md:h-8 lg:w-12 lg:h-12 -translate-y-9' src={dotSquareRed} alt='dot' />
          <img className='w-4 h-4 md:w-8 md:h-8 lg:w-12 lg:h-12 -translate-y-12' src={dotCircleBlue} alt='dot' />
          <img className='w-4 h-4 md:w-8 md:h-8 lg:w-12 lg:h-12 -translate-y-14' src={dotSquareBlue} alt='dot' />
          <img className='w-4 h-4 md:w-8 md:h-8 lg:w-12 lg:h-12 -translate-y-12' src={dotCircleRed} alt='dot' />
          <img className='w-4 h-4 md:w-8 md:h-8 lg:w-12 lg:h-12 -translate-y-5' src={dotSquareRed} alt='dot' />
        </div>
        <div className='flex'>
          <img src={cars} className='w-12 h-12 md:w-24 md:h-24 lg:w-32 lg:h-32 rotate-45 animate-pulse' alt='cars' />
          <div className='flex-1 px-4'>
            <h1 className='flex-1 md:text-4xl hidden md:block lg:text-6xl font-extrabold text-center text-transparent bg-clip-text bg-gradient-to-r from-blue-500 via-purple-600 to-pink-400'>Twin Cars - Ace Of Fun</h1>

          </div>
          <img src={barrier} className='w-12 h-12 md:w-24 md:h-24 lg:w-32 lg:h-32' alt='barriet' />
        </div>
        <div className='pt-14 flex flex-col'>
          <h1 className='flex-1 md:hidden text-4xl lg:text-6xl font-extrabold text-center text-transparent bg-clip-text bg-gradient-to-r from-blue-500 via-purple-600 to-pink-400'>
            Twin Cars - Ace Of Fun
          </h1>
          <div className='self-center text-center pt-4'>
            <h2 className='text-xl font-bold text-white mb-4'>Get it on</h2>
            <div className='flex justify-center items-center md:flex-row flex-col'>
              <div className='px-4 pb-4 border-2 border-white rounded-xl flex justify-center items-center hover:bg-gray-800'>
                <a className='flex justify-center items-center w-full h-full' href='https://apps.apple.com/us/app/twin-cars-ace-of-fun/id6741724417' target='_blank'> <img className='w-16 inline-block pt-3 mx-2' src={appStoreIcon} alt='App Store' />
                  <span className='text-2xl text-white '>App Store</span>
                </a>
              </div>
              <div className='px-4 pb-4 border-2 border-white rounded-xl flex justify-center items-center mt-4 md:mt-0 md:ml-4 hover:bg-gray-800'>
                <a className='flex justify-center items-center w-full h-full' href='https://play.google.com/store/apps/details?id=com.praleetechnology.twincars' target='_blank'> <img className='w-16 inline-block pt-3 mx-2' src={googleplay} alt='google play' />
                  <span className='text-2xl text-white'>Google Play Store</span>
                </a>
              </div>
            </div>

          </div>
          <div className='flex justify-center items-center flex-col mt-11'>
            <iframe className='w-[400px] h-[250px] sm:w-[520px] sm:h-[315px] md:w-[640px] md:h-[420px] lg:w-[960px] lg:h-[520px] rounded-xl' src="https://www.youtube.com/embed/0e8j4veYu7Q" title="Twin Cars Ace of Fun  2.0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

            <p className='text-gray-400 mt-8 text-center lg:px-16'>
              Experience the thrill of "Twin Cars Ace Of Fun," where you control two cars on an endless road, navigating through a series of obstacles and oncoming traffic. As the road stretches infinitely, your reflexes and strategy are tested to their limits. Tap to switch lanes and swipe up to launch missiles, avoid collisions, and collect power-ups, all while managing both cars simultaneously. With increasing speed and chaos, "Twin Cars Ace Of Fun" offers a unique and challenging gameplay experience that will keep you on the edge of your seat. Aim for high scores and compete with yourself in this adrenaline-pumping adventure. Are you ready to handle the dual control and become the ultimate driver? Buckle up and let the drift begin!
            </p>

            <p className='mt-8 text-2xl text-white text-center'>
            Feel Free To Contact Us For Any Support
            </p>
            <p className='mt-4 text-2xl  text-center'>
              <a href='mailto:info@praleetechnology.com' className='text-gray-400 hover:text-white transition-all duration-300'>info@praleetechnology.com</a><span className='text-gray-400'>,</span> <a href='mailto:info@praleetechnologies.com' className='text-gray-400 hover:text-white transition-all duration-300'>info@praleetechnologies.com</a>
            </p>
          </div>
        </div>
      </div>

      {/* <TechForGaming /> */}
      <div className='bg-gradient-to-r from-blue-600 via-purple-600 to-pink-400  mt-0' style={{
        backgroundImage: `url(${bannerbg})`,
        backgroundAttachment: 'fixed'
      }}>
        <div className='bg-gray-900 bg-opacity-10 py-4 md:py-32 w-full flex justify-center'>
          <p className='text-2xl md:text-4xl lg:text-6xl text-white text-center font-extrabold rounded-3xl p-10 md:py-12 md:px-24 bg-gray-900 bg-opacity-10 backdrop-blur-2xl w-max'>
            Get ready to play, and<br />level up your fun factor
          </p>
        </div>
      </div>

    </div>
  )
}
