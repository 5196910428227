import React from 'react'
import img from '../../assets/images/bgpic2.jpg'
import PageHeader from '../common/PageHeader'
import CreditPersonSection from '../caredits/CreditPersonSection'

const TwinCarsPrivacy = () => {
  return (
    <div>
      <PageHeader img={img} >
        Twin Cars - Ace of Fun<br />Privacy Policy
      </PageHeader>

      <CreditPersonSection designation="Privacy Policy" isImageInRight={true}>
        <p className='text-gray-400 text-xl font-thin'>
          This privacy policy applies to the Twin Cars Ace Of Fun (hereby referred to as "Game") for mobile devices that was created by Dheeraj Sapkale (hereby referred to as service provider - "Pralee <del>Technology & Games</del> <ins>Technologies</ins>") as a Free service.
          We take your privacy very seriously. This Privacy Policy is designed to explain how we collect, use, and disclose your information when you use our game.
          By accessing or using our game, you agree to the terms of this Privacy Policy.
        </p>
        <p className='text-gray-400 text-xl font-thin mt-5'>
          <b className='font-bold my-5'>Collection of Information</b> <br />
          We do not collect any personal information about you when you use our game.
          However, our game includes third-party ads from Unity Level Play which collects
          information about how users play games, including level and time spent playing,
          to improve the user experience.
          <br />
          <br /> <b className='my-5'>What information does third-party ads collect?</b>
          <br />

          User ID,
          Device identifiers, including advertiser ID,
          Browser information,
          Session information,
          Events like monetization and app interactions,
          IP addresses,
          Metadata about in-game communications, and
          Purchase details.




          <p className='mt-4'>
            <b className='font-bold'>Device Information:</b> Information about the device you are using to access our game, including the type of device, its operating system, and unique device identifiers.
          </p>
          <p className='mt-4'>
            <b className='font-bold'>Usage Information:</b> Information about how you use our game, including the pages you visit, the time and date of your visits, and the links you click on.
          </p>
        </p>
        <p className='text-gray-400 text-xl font-thin mt-5'>
          <b className='font-bold my-5'>Use of Information</b> <br />
          We do not use any personal information about you because we do not collect any personal information about you. The third-party ads that are included in our game may use the information they collect for various purposes, including:
          <p className='mt-4'>
            To improve the user experience, such as allowing users to redeem rewards and return to where they left off in a game,
            to create aggregated reports about game activity across a number of games,
            to help developers make decisions about how to run their games, and
            for personalized advertising.
          </p>

        </p>
        <p className='text-gray-400 text-xl font-thin mt-5'>
          <b className='font-bold my-5'>Disclosure of Information</b> <br />
          We do not disclose any personal information about you because we do not collect any personal information about you. The third-party ads that are included in our game may disclose the information they collect to third parties in the following circumstances:
          <ul className='mt-4'>
            <li>To comply with applicable laws, regulations, or legal proceedings.</li>
            <li>To enforce their terms of service or other agreements.</li>
            <li>To protect their rights or property, or the safety of their users or others.</li>
          </ul>

        </p>
        <p className='text-gray-400 text-xl font-thin mt-5'>
          <b className='font-bold my-5'>Data Security</b> <br />
          We take reasonable measures to protect any non-personal information we collect from unauthorized access, use, and disclosure.
          But remember that no method of transmission over the internet, or method of electronic
          storage is 100% secure and reliable, and we cannot guarantee its absolute security.
        </p>
        <p className='text-gray-400 text-xl font-thin mt-5'>
          <b className='font-bold my-5'>Children's Privacy</b> <br />
          We do not use the Game to knowingly solicit data from or market to children under the age of 13.
          The Game does not address anyone under the age of 13. 
          We do not knowingly collect personally identifiable information from children under 13 years of age. 
          If you believe that we have collected personal information from a child under the age of 13, please contact us immediately 
          at <a href="mailto:info@praleetechnology.com">info@praleetechnology.com</a>, <a href="mailto:info@praleetechnologies.com">info@praleetechnologies.com.</a> 
        </p>
        <p className='text-gray-400 text-xl font-thin mt-5'>
          <b className='font-bold my-5'>Updates to Privacy Policy</b> <br />
          We may update this Privacy Policy from time to time.
          If we make material changes to this Privacy Policy,
          we will notify you by email or by posting a notice on our website.
          Your continued use of our game after such changes will indicate your acceptance of the updated Privacy Policy.

        </p>
        <p className='text-gray-400 text-xl font-thin mt-5'>
          If you have any questions about this Privacy Policy, please contact us at <a href="mailto:info@praleetechnology.com">info@praleetechnology.com</a>, <a href="mailto:info@praleetechnologies.com">info@praleetechnologies.com.</a> 
        </p>

      </CreditPersonSection>

    </div>
  )
}

export default TwinCarsPrivacy;